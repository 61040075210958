<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header"
import Footer from "@/components/Footer"

export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Chango&family=Krona+One&display=swap');

#app {
  /* color palette */
  --color1: #372e59;
  --main-color: #4a3060;
  --color3: #6b2270;
  --color4: #82583a;
  --color5: #639a3e;
  --bg: #fbf3e2;
  /* fonts */
  --theme-font: 'Chango', cursive;
  --text-font: Verdana, 'Geneva', sans-serif;
  --header-font: 'Krona One', sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  color: #4a3060;
  font-family: 'Chango', cursive;
  background-color: #fbf3e2;
}

h1 {
  font-size: 1.5rem;
}

a {
  color: #4a3060;
  position: relative;
  text-decoration: none;
}
a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #4a3060;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}
a:focus::before, a:hover::before, a.selected::before {
  visibility: visible;
  transform: scaleX(1);
}

/* .grad-animation {
  background: radial-gradient(#372e59 30%, #4a3060 45%, #6b2270 60%, #82583a 75%, #639a3e 100%);
  background-size: 200% auto; 
  color: #4a3060;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 60s linear infinite alternate; 
}
@keyframes shine {
  to {
    background-position: 200% center;
  }
} */
</style>
