<template>
  <div id="logo">
    <div class="grad-animation"><a class="selected">m.</a></div>
  </div>
</template>

<script>
export default {
  name: 'Logo'
}
</script>
<style>
#logo {
  position: fixed;
  font-size: 1.9rem;
  top: 10px;
  border: solid var(--main-color) 3px;
  border-radius: 50px;
  height: 50px;
  width: 50px;
  padding: 2px;
  background-color: var(--bg);
  z-index: 1;
}
div#logo > div.grad-animation {
  margin-top: 3px;
}
div#logo > div.grad-animation a {
  text-decoration: none;
}
</style>