<template>
  <footer>
    <a href="https://www.linkedin.com/in/marjorie-etienne-7a07234a/" target="_blank">
      <i class="fab fa-linkedin"></i>
    </a>
    <a href="https://github.com/Schmarj3" target="_blank">
      <i class="fab fa-github-square"></i>
    </a>
    <a href="https://twitter.com/theladymarjorie">
      <i class="fab fa-twitter-square"></i>
    </a>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
footer {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  padding-bottom: 5%;
}
a > i {
  font-size: 40px;
}
</style>