<template>
  <section class="greeting">
      <p class="line-1 anim-typewriter-1">Hi~!</p> 
      <p class="line-1 anim-typewriter-2">I'm</p>
      <p class="line-1 anim-typewriter-3">Marj</p> 
    </section>
</template>

<script>
export default {
  name: 'Hi'
}
</script>

<style scoped>
.greeting {
  height: calc(80vh - 8em);
  padding: 4em;
  color: var(--main-color);
} 
.line-1{
    position: relative;
    top: 40%;  
    width: 3em;
    margin: 0 auto;
    border-right: 2px solid var(--bg);
    font-size: 380%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);   
}
/* Greeting Animation */
.anim-typewriter-1{
  animation: typewriter 1s steps(4) 1s 1 normal both, 
    blinkTextCursor 900ms steps(44) 3 normal forwards;           
}  
.anim-typewriter-2{
  animation: typewriter-2 1s steps(3) 2s 1 normal both,
  blinkTextCursor 900ms steps(44) 2.5s normal forwards;
}
.anim-typewriter-3{
  animation: typewriter 1s steps(5) 3s 1 normal both,
  blinkTextCursor2 900ms steps(44) 3s infinite normal forwards;
} 
@keyframes typewriter{
  from{width: 0;}
  to{width: 3em;}
}
@keyframes typewriter-2{
  from{width: 0;}
  to{width: 2.2em;}
}
@keyframes blinkTextCursor{
  from{border-right-color: var(--main-color);}
  to{border-right-color: transparent;}
}
@keyframes blinkTextCursor2{
  from{border-right-color: transparent;}
  to{border-right-color: var(--main-color);}
}   

.content, .jounal-content, .project-content{
  min-height: calc(80vh - 8em);
  padding: 4em;
  max-width: 70%;
  margin: 0 auto;
}
@media only screen and (max-width: 780px) {
  .content {
    max-width: 80%;
  }
}
@media only screen and (max-width: 650px) {
  .content {
    padding: 4rem 1rem;
  }
}

.content-text, .project-text {
  position: relative;
  top: 20%;
  padding-left: 5%; 
  line-height: 2.1rem;
  font-family: var(--text-font);
  font-size: 1.5rem;
}

.project-content {
  min-height: 0px;
}

.project-text {
  padding-left: 0px;
}

article {
  margin-bottom: 100px;
}
.journal-header {
  border-top: solid var(--main-color) 5px;
  font-family: var(--header-font);
}
.topics {
  font-family: var(--header-font);
  font-size: 1.1rem;
  color: var(--bg);
  background-color: var(--main-color);
  padding: 5px;
}
.bold {
  font-weight: bolder;
}
</style>