<template>
  <div class="home">
    <Hi />
  </div>
</template>

<script>
// @ is an alias to /src
import Hi from "@/components/Hi"

export default {
  name: "Home",
  components: {
    Hi,
  },
};
</script>
