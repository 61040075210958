<template>
  <header id="nav">
    <router-link to="/">
      <Logo />
    </router-link>
    <!-- <router-link to="/about" class="nav-item">About</router-link> -->
    <!-- <router-link to="/journal" class="nav-item">Musings</router-link> -->
    <router-link to="/clubhouse" class="nav-item">CH</router-link>
  </header>
</template>

<script>
import Logo from "@/components/Logo"

export default {
  name: 'Header',
  components: {
    Logo,
  },
}
</script>

<style>
header {
  width: 100%;
  max-width: 1400px;
}
#nav {
  text-align: right;
  height: 80px;
}
.nav-item {
  display: inline-block;
  font-size: 2rem;
  margin: 5px 15px;
}
/* @media only screen and (max-width: 650px) {
  .nav-item {
    display: block;
  }
} */
</style>